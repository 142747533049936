<template>
  <div v-if="userHasPermission('view employees')">
    <vx-card>
      <vs-row>
        <vs-col vs-type="flex" vs-align="center" vs-w="8">
          <h4 class="mb-4">Mitarbeiter bearbeiten</h4><br>
        </vs-col>
        <vs-col vs-type="flex" class="justify-end" vs-align="center" vs-w="4">
          <vs-switch color="success" v-model="isonline" type="border" class="mb-4"></vs-switch>
          <label class="pl-2 pb-4" v-if="isonline == '1'">Online</label>
          <label class="pl-2 pb-4" v-if="isonline == '0'">Nicht online</label>
        </vs-col>
      </vs-row>
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-1">
          <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/2 w-full mb-0">
              <vs-select
                  class="selectExample w-full"
                  label="Anrede"
                  v-model="salutation"
              >
                <vs-select-item :is-selected.sync="item.isSelected" :key="index" :value="item.value"
                                :text="item.label"
                                v-for="item,index in salutations"/>
              </vs-select>
            </div>
          </div>
          <div class="vx-row mb-1">
            <div class="vx-col sm:w-1/2 w-full mb-0">
              <vs-input class="w-full" v-validate="'required|min:3'" label="First Name" name="firstname"
                        v-model="firstname"/>
              <span class="text-danger text-sm">{{ errors.first('firstname') }}</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-0">
              <vs-input class="w-full" v-validate="'required|min:3'" label="Last Name" name="lastname"
                        v-model="lastname"/>
              <span class="text-danger text-sm">{{ errors.first('lastname') }}</span>
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col w-full">
              <vs-input class="w-full" v-validate="'required|email'" label="Email" type="email"
                        name="email" v-model="email"/>
              <span class="text-danger text-sm">{{ errors.first('email') }}</span>
            </div>


          </div>
          <div class="vx-row mb-1">
            <div class="vx-col w-full">
              <label class="vs-input--label">E-Mail Signatur</label>
              <editor api-key="qxbzwh7n69tctq7p5bta4qtatjs7c35wi7juwgf2rcpf29lu" :init="initTinyMce"
                      v-model="signature"/>
            </div>
          </div>
        </div>

        <div class="vx-col sm:w-1/2 w-full mb-1">
          <div class="vx-row mt-4">
            <div class="vx-col lg:w-1/2">
              <vs-select label="Mitarbeiter-Typ" class="w-full" v-model="type">
                <vs-select-item :key="index" :value="type.value"
                                :text="type.label"
                                v-for="(type,index) in typeOptions"/>
              </vs-select>
            </div>
            <div class="vx-col lg:w-1/2">


              <label class="pt-2 lg:w-2/2">Position</label>
              <vs-input
                  v-model="position"
                  v-validate="'required|min:1'"
                  class="lg:w-4/4 w-full"
                  type="text"
                  name="position">
              </vs-input>
              <span class="text-danger text-sm">{{ errors.first('position') }}</span>

            </div>
          </div>
          <div class="vx-row mt-4 lg:w-1/3">
            <div class="vx-col  picture-upload flex">
              <vs-upload text="Bild hochladen"/>
            </div>
          </div>
          <div class="vx-row mt-4">
            <div class="vx-col lg:w-full">
              <vs-checkbox>Gravatar verwenden</vs-checkbox>
            </div>
          </div>
        </div>
      </div>


    </vx-card>
    <vx-card title="Zugang zum Portal" class="mt-6">
      <vs-row>
        <div class="vx-col sm:w-full w-full mb-1">
          <div class="vx-row mt-4">
            <div class="vx-col lg:w-1/3">
              <vs-col vs-type="flex" vs-align="center">
                <vs-switch color="warning" type="border" class="mb-4"></vs-switch>
                <label class="pl-2 pb-4">Passwort bearbeiten</label>
              </vs-col>
              <vs-col vs-type="flex" vs-align="center">
                <div class="vx-col lg:w-full">
                  <vx-input-group class="mb-0">
                    <vs-input v-model="password" v-validate="'required|min:10'"
                              placeholder="neues Passwort festlegen" name="password"/>
                    <template slot="append">
                      <div class="append-text btn-addon">
                        <vs-button color="primary" @click="generatePassword">Generieren
                        </vs-button>
                      </div>
                    </template>
                  </vx-input-group>
                  <span class="text-sm p-text-color">"Password vergessen" Mail zusenden</span>
                </div>
              </vs-col>
            </div>
            <div class="vx-col lg:w-1/5">
              <vs-col>
                <div class="flex">
                  <vs-switch color="success" v-model="active" type="border" class="mb-4"></vs-switch>
                  <label class="pl-2 pb-4" v-if="active == '0'">Nicht Aktiv</label>
                  <label class="pl-2 pb-4" v-if="active == '1'">Aktiv</label>
                </div>
              </vs-col>
              <vs-col vs-align="center">
                <p>Letzter Login: {{ last_login }}</p>
                <p>Erstell am: {{ created_at }}</p>
                <p>Letzte Änderungen:{{ updated_at }}</p>
                <p></p>
                <p></p>
              </vs-col>
            </div>
            <div class="vx-col lg:w-1/5">
              <vs-col vs-type="flex" vs-align="center">
                <div class="vx-col lg:w-full">
                  <vs-select
                      class="selectExample w-full"
                      label="Sprache im Portal"
                      v-model="language">
                    <vs-select-item :is-selected.sync="item.isSelected" :key="index"
                                    :value="item.value" :text="item.label"
                                    v-for="item, index in languages"/>
                  </vs-select>
                </div>
              </vs-col>
            </div>
            <div class="vx-col lg:w-1/5">
              <vs-col vs-type="flex" vs-align="center">
                <div class="vx-col lg:w-full">
                  <vs-select label="Timezone" v-model="timezone" class="w-full"></vs-select>
                </div>
              </vs-col>
            </div>
          </div>
        </div>
      </vs-row>
    </vx-card>
    <div class="mt-6 flex">
      <vx-card title="Berechtigungen" class="mr-4 lg:w-1/2">
        <vs-row>
          <vs-col>
            <div class="lg:w-1/2">
              <vs-select
                  class="selectExample w-full"
                  label="Rolle"
                  v-model="role"
              >
                <vs-select-item :key="index" :value="item.id" :text="item.long_name"
                                v-for="item,index in roles"/>
              </vs-select>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
      <vx-card title="Teamzuordung" class="ml-4 lg:w-1/2">
        <vs-row>
          <vs-col>
            <div class="lg:w-1/2">
              <vs-checkbox>Projektmanagement</vs-checkbox>
              <vs-checkbox v-model="checkbox1" class="pt-2">Entwicklung</vs-checkbox>
              <vs-checkbox class="pt-2">Design</vs-checkbox>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
    <div class="mt-6 flex">

      <working-time-settings class="mr-4 lg:w-1/2">
      </working-time-settings>

      <vx-card title="Provisionen" class="ml-4 lg:w-1/2">
        <vs-row>
          <vs-col>
            <div class="flex" v-if="commissionAccounting">
              <vs-switch color="success" v-model="commissionAccounting" @change="deactivateCommision"
                         :disabled="disableIfisHidden('provision')"></vs-switch>
              <label class="pl-4">Provisionsbasierte Abrechnung</label>
            </div>
            <div class="flex" v-if="!commissionAccounting">
              <vs-switch v-model="commissionAccounting" @change="activateCommision"></vs-switch>
              <label class="pl-4">Provisionsbasierte Abrechnung</label>
            </div>
            <div class="flex pt-2" v-if="commissionAccounting">
              <vs-input
                  v-model="provision"
                  class="w-full lg:w-1/6"
                  type="text"
                  :disabled="disableIfisHidden('provision')">
              </vs-input>
              <label class="w-1/2 m-auto ml-4">je 1€ Umsatz / Abrechnung am Monatsend</label>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-8">
          <h4>Bonusprogramm</h4>
          <vs-row>
            <div class="flex pt-2">
              <vs-switch color="success" v-model="bonusprogram"></vs-switch>
              <label class="pl-4">aktiviert</label>
            </div>

            <BonusprogramSettings v-if="bonusprogram"></BonusprogramSettings>
          </vs-row>

        </vs-row>
      </vx-card>
    </div>
    <div class="mt-6 flex">
      <vx-card title="Mitarbeiter Informationen" class="mr-4 lg:w-1/2">
        <vs-row>
          <vs-col>
            <div class="lg:w-full flex pt-2">
              <label class="pt-2 lg:w-1/6">Tel.: Geschäft</label>
              <vs-input
                  v-model="businessphone"
                  class="lg:w-1/4 w-full"
                  type="text"
                  name="business_phone"
                  :disabled="disableIfisHidden('business_phone')">
                >
              </vs-input>
            </div>
            <div class="lg:w-full flex pt-2">
              <label class="pt-2 lg:w-1/6">Min Auslastung in % <small>(abrechenbar)</small></label>
              <vs-input
                  v-model="min_workload"
                  class="lg:w-1/4 w-full"
                  type="text"
                  name="min_workload"
                  :disabled="disableIfisHidden('min_workload')">>
              </vs-input>
            </div>
            <div class="lg:w-full flex pt-2">
              <label class="pt-2 lg:w-1/6">Ziel Auslastung in % <small>(abrechenbar)</small></label>
              <vs-input
                  v-model="target_workload"
                  class="lg:w-1/4 w-full"
                  type="text"
                  name="target_workload"
                  :disabled="disableIfisHidden('target_workload')">>
              </vs-input>
            </div>
            <div class="lg:w-full flex pt-2">
              <label class="pt-2 lg:w-1/6">Stundensatz gegenüber Kunden</label>
              <vs-input
                  v-model="client_price_per_hour"
                  class="lg:w-1/4 w-full"
                  type="text"
                  name="client_price_per_hour"
                  :disabled="disableIfisHidden('client_price_per_hour')">>
              </vs-input>
            </div>
            <div class="lg:w-full flex pt-2">
              <label class="pt-2 lg:w-1/6">EUR / Überstunde</label>
              <vs-input
                  v-model="overtime_price_per_hour"
                  class="lg:w-1/4 w-full"
                  type="text"
                  name="overtime_price_per_hour"
                  :disabled="disableIfisHidden('overtime_price_per_hour')">>
              </vs-input>
            </div>
            <div class="lg:w-full flex pt-2">
              <label class="pt-2 lg:w-1/6">Überstunde Notiz intern</label>
              <vs-textarea
                  v-model="overtime_notice"
                  class="lg:w-1/4 w-full"
                  type="text"
                  name="overtime_notice"
                  :disabled="disableIfisHidden('overtime_notice')">>
              </vs-textarea>
            </div>
            <div class="lg:w-full flex pt-2">
              <label class="pt-2 lg:w-1/6">Gehalt/Monat</label>
              <vs-input
                  v-model="monthly_salary"
                  class="lg:w-1/4 w-full"
                  type="text"
                  name="monthly_salary"
                  :disabled="disableIfisHidden('monthly_salary')">>
              </vs-input>
            </div>
            <div class="lg:w-full flex pt-2">
              <label class="pt-2 lg:w-1/6">Erster Arbeitstag</label>
              <vs-input
                  v-model="first_working_day"
                  class="lg:w-1/4 w-full"
                  type="date"
                  name="first_working_day"
                  :disabled="disableIfisHidden('first_working_day')">>
              </vs-input>
            </div>
            <div class="lg:w-full flex pt-2">
              <label class="pt-2 lg:w-1/6">PAYMO User Id <br><small>(Für Paymo Berichte)</small></label>
              <vs-input
                  v-model="paymo_id"
                  class="lg:w-1/4 w-full"
                  type="text"
                  name="paymo_id"
                  :disabled="disableIfisHidden('paymo_id')">>
              </vs-input>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
      <vx-card title="Privat" class="ml-4 lg:w-1/2">
        <vs-row>
          <vs-col>
            <div class="lg:w-1/2 flex">
              <label class="pt-2 lg:w-1/3">Geburtstag</label>
              <div class="pl-2 w-2/3">
                <vs-input
                    v-model="birthday"
                    class=" w-full"
                    type="date"
                    name="birthday"
                    :disabled="disableIfisHidden('birthday')">
                </vs-input>

              </div>

            </div>
            <div class="lg:w-1/2 flex pt-2">
              <label class="pt-2 lg:w-1/3">Straße + HN</label>
              <div class="pl-2 w-2/3">
                <vs-input
                    v-model="privatestreet"

                    class="w-full"
                    type="text"
                    name="privatestreet"
                    :disabled="disableIfisHidden('private_street')">
                </vs-input>

              </div>
            </div>
            <div class="flex pt-2">
              <label class="pt-2 lg:w-1/6">PLZ / Stadt</label>
              <vs-input
                  v-model="privatezipcode"

                  class="lg:w-1/4 w-full"
                  type="text"
                  name="private_zip_code"
                  :disabled="disableIfisHidden('private_zip_code')">
              </vs-input>

              <vs-input
                  v-model="privatecity"

                  class="lg:w-1/4 w-full pl-2"
                  type="text"
                  name="private_city"
                  :disabled="disableIfisHidden('private_city')">
              </vs-input>

            </div>
            <div class="lg:w-1/2 flex pt-2">
              <label class="pt-2 lg:w-1/3">Tel.: Privat</label>
              <vs-input
                  v-model="privatephone"
                  class="lg:w-1/2 w-full"
                  type="text"
                  name="private_phone"
                  :disabled="disableIfisHidden('private_phone')">
              </vs-input>

            </div>
            <div class="flex pt-2">
              <label class="pt-2 lg:w-1/6">IBAN</label>
              <vs-input
                  v-model="iban"
                  class="lg:w-1/2 w-full"
                  type="text"

                  name="iban"
                  :disabled="disableIfisHidden('iban')">
              </vs-input>

            </div>
            <div class="flex pt-2">
              <label class="pt-2 lg:w-1/6">BIC</label>
              <vs-input
                  class="lg:w-1/2 w-full"
                  type="text"
                  v-model="bic"
                  name="bic"
                  :disabled="disableIfisHidden('bic')">
              </vs-input>
            </div>
            <div class="flex pt-2">
              <label class="pt-2 lg:w-1/6">E-Mail</label>
              <vs-input
                  class="lg:w-1/2 w-full"
                  type="email"
                  v-model="privatemail"
                  name="private_mail"
                  :disabled="disableIfisHidden('private_mail')">
              </vs-input>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>

    <div class="flex mt-8 justify-end">
      <vs-button class="mr-3 mb-2" :disabled="!validateForm" @click="updateUser">Speichern</vs-button>
      <vs-button color="warning" type="border" class="mb-2" to="/employees">Abbrechen</vs-button>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../api/index";
import staticOptions from "@/mixins/static/options";

import Editor from '@tinymce/tinymce-vue'
import WorkingTimeSettings from "./components/WorkingTimeSettings";
import BonusprogramSettings from "./components/BonusprogramSettings";
import {mapGetters} from "vuex";

export default {
  name: "Employee",
  components: {
    WorkingTimeSettings,
    BonusprogramSettings,
    'editor': Editor
  },
  data() {
    return {
      commissionAccounting: true,
      hiddenFields: [],
      checkbox1: true,
      userId: null,
      roles: [],
      salutation: 1,
      firstname: "",
      lastname: "",
      active: "",
      isonline: "",
      birthday: "",
      privatestreet: "",
      privatezipcode: "",
      privatecity: "",
      privatephone: "",
      iban: "",
      bic: "",
      privatemail: "",
      businessphone: "",
      target_workload: "",
      min_workload: "",
      client_price_per_hour: "",
      overtime_price_per_hour: null,
      overtime_notice: "",
      provision: 0,
      bonusprogram: 0,
      position: "",
      created_at: null,
      updated_at: null,
      last_login: null,
      language: "",
      languages: [
        {value: 1, label: 'Deutsch', isSelected: true},
        {value: 2, label: 'Englisch', isSelected: false},
        {value: 3, label: 'Französich', isSelected: false},
      ],
      timezone: "",
      type: "",
      email: "",
      password: "",
      role: null,
      paymo_id: "",
      salutations: [
        {value: 1, label: 'Herr', isSelected: true},
        {value: 2, label: 'Frau', isSelected: false},
        {value: 3, label: 'Diverse', isSelected: false},
      ],
      userData: [],
      signature: null,
      signatureHtmlMode: false,
      monthly_salary: null,
      first_working_day: "",
      initTinyMce: {
        height: 400,
        menubar: true,
        plugins: "code table"
      }
    }
  },
  created() {
    this.userId = this.$route.params.id;

    if (!this.userId) {
      this.$router.push('/employees')
      return;
    }

    this.$vs.loading()

    ApiService.get('users/' + this.userId).then(response => {


      let userData = response.data.result;
      this.hiddenFields = response.data.hiddenFields;

      this.firstname = userData.detail.first_name;
      this.lastname = userData.detail.last_name;
      this.language = userData.detail.language;
      this.paymo_id = userData.detail.paymo_id;
      this.timezone = userData.detail.timezone;
      this.signature = userData.detail.signature;
      this.active = userData.active;
      this.isonline = userData.is_online;
      this.last_login = userData.last_login;
      this.created_at = userData.created_at;
      this.updated_at = userData.updated_at;
      this.email = userData.email;
      this.type = userData.typ;
      this.birthday = userData.employee_settings.birthday;
      this.first_working_day = userData.employee_settings.first_working_day;
      this.privatestreet = userData.employee_settings.private_street;
      this.privatezipcode = userData.employee_settings.private_zip_code;
      this.privatecity = userData.employee_settings.private_city;
      this.bic = userData.employee_settings.bic;
      this.iban = userData.employee_settings.iban;
      this.privatephone = userData.employee_settings.private_phone;
      this.privatemail = userData.employee_settings.private_mail;
      this.businessphone = userData.employee_settings.business_phone;
      this.target_workload = userData.employee_settings.target_workload;
      this.min_workload = userData.employee_settings.min_workload;
      this.client_price_per_hour = userData.employee_settings.client_price_per_hour;
      this.overtime_price_per_hour = userData.employee_settings.overtime_price_per_hour;
      this.overtime_notice = userData.employee_settings.overtime_notice;

      this.annual_leave_days_number = userData.employee_settings.annual_leave_days_number;
      this.workday_hours = userData.employee_settings.workday_hours;
      this.monthly_salary = userData.employee_settings.monthly_salary;


      this.provision = userData.employee_settings.provision;
      this.bonusprogram = userData.employee_settings.bonusprogram;
      this.position = userData.employee_settings.position;
      this.password = "";

      if (userData.employee_settings.provision >= 1) {
        this.commissionAccounting = true;
      } else {
        this.commissionAccounting = false;
      }

      if (userData.roles.length > 0) {
        this.role = userData.roles[0].id;
      }

      this.salutation = userData.detail.salutation_id;

      ApiService.get('roles').then(response => {
        this.roles = response.data.result;
        this.$vs.loading.close()
      })

    }).catch(error => {

      let errorMessage = error.message;

      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      this.$vs.notify({
        title: 'Error',
        text: errorMessage,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })

      setTimeout(() => this.$router.push({path: '/employees'}), 3000);

    })
  },
  computed: {
      ...mapGetters(['userHasPermission', 'userId', 'user']),
    validateForm() {
      return !this.errors.any() && this.email !== '' && this.firstname !== '' && this.lastname !== '';
    },
    typeOptions() {
      return staticOptions.employeeTypes;
    },
  },
  methods: {
    disableIfisHidden(name) {
      for (var i = 0; i < this.hiddenFields.length; i++) {
        if (this.hiddenFields[i] == name) {
          return true;
        }
      }
      return false;
    },
    deactivateCommision() {
      // this.commissionAccounting = false;
      this.provision = "";
    },
    activateCommision() {
      this.provision = 10;
    },
    generatePassword() {
      var length = 10,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return this.password = retVal;
    },
    updateUser() {
      let me = this;

      if (!this.validateForm) return

      me.$vs.loading()

      if (this.commissionAccounting === false) {
        this.provision = null;
      }

      let payload = {
        email: this.email,
        role_id: this.role,
        typ: this.type,
        active: this.active,
        is_online: this.isonline,
        detail: {
          last_name: this.lastname,
          first_name: this.firstname,
          language: this.language,
          timezone: this.timezone,
          salutation_id: this.salutation,
          signature: this.signature,
          paymo_id: this.paymo_id
        },
        employee_settings: {
          birthday: this.birthday,
          first_working_day: this.first_working_day,
          private_street: this.privatestreet,
          private_zip_code: this.privatezipcode,
          private_city: this.privatecity,
          bic: this.bic,
          iban: this.iban,
          private_phone: this.privatephone,
          private_mail: this.privatemail,
          business_phone: this.businessphone,
          provision: this.provision,
          bonusprogram: this.bonusprogram,
          position: this.position,
          overtime_price_per_hour: parseInt(this.overtime_price_per_hour),
          client_price_per_hour: parseInt(this.client_price_per_hour),
          target_workload: parseInt(this.target_workload),
          min_workload: parseInt(this.min_workload),
          overtime_notice: this.overtime_notice,
          monthly_salary: parseInt(this.monthly_salary),
        },

      }

      if (this.password && this.password !== "") {
        payload.password = this.password
      }

      ApiService.put('users/' + this.userId, payload).then((response) => {

        me.$vs.loading.close()

        me.$vs.notify({
          title: 'Erfolgreich',
          text: 'Ihr Account wurde erfolgreich bearbeitet',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })

        //  me.$router.push('/employees');

      }).catch((response) => {

        me.$vs.loading.close()

        me.$vs.notify({
          title: 'Error',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      });

    }

  }
}
</script>

<style lang="scss" scoped>
.p-text-color {
  color: rgba(0, 0, 0, 0.4);
}

</style>
