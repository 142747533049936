import Store from '../../store/store';

const ModelFactory = {
    create(tableName, withRelations = []) {
        const definition = this._getDefinitionByTableName(tableName);
        return this._createFields(definition, withRelations)
    },

    _getDefinitionByTableName(tableName) {
        return Store.getters.getModelDefinitionByTableName(tableName);
    },

    _createFields(definition, withRelations = []) {
        const fields = {};
        definition.columns.forEach(column => {
            if(!['id', 'created_at', 'updated_at'].includes(column.field)) {
                if(column.default !== null && column.default !== undefined) {
                    fields[column.field] = column.default;
                }
            }
        });

        if(withRelations.length > 0) {
            definition.relations.forEach(relation => {
                if(withRelations.includes(relation.field)) {
                    let value = null;
                    if(['HasMany', 'MorphToMany', 'BelongsToMany'].includes(relation.type)) {
                        value = [];
                    }
                    fields[relation.field] = value;
                }
            });
        }

        return fields;
    }
};

export default ModelFactory;