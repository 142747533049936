<template>
  <vs-row class="mt-5">


    <vs-table :data="absenceQuotas"  class="w-full">
      <template slot="header">
        <h6 class="mb-2">Bonusziele</h6>
        <vs-button size="small" class="mb-2" @click="onClickCreateAbsenceQuota">Hinzufügen</vs-button>
      </template>

      <template slot="thead">
        <vs-th>Zeitspanne</vs-th>
        <vs-th>Zielpunkte</vs-th>
        <vs-th>Erreicht</vs-th>
        <vs-th>Notiz</vs-th>
        <vs-th style="width: 100px">Aktionen</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr v-for="row in data">
          <vs-td>
            Q{{ row.quarter }} / {{ row.year }}
          </vs-td>
          <vs-td>
            {{ row.bonus_target }}
          </vs-td>
          <vs-td>
            {{ row.reached }}
          </vs-td>
          <vs-td>
            {{ row.notice }}
          </vs-td>
          <vs-td style="width: 100px" class="text-right">
            <vs-button
                class="inline-block"
                size="small"
                color="warning"
                type="filled"
                icon="edit"
                @click="onClickEditAbsenceQuota(row)">
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <user-bonus-target-popup
        @change="fetchData"
        :absence-quota="absenceQuota"
        :active.sync="absenceQuotaPopupActive">
    </user-bonus-target-popup>

  </vs-row>
</template>

<script>
import moment from 'moment';
import ApiService from "../../../../api";
import UserBonusTargetPopup from "./UserBonusTargetPopup";
import ModelFactory from "../../../../mixins/model/model-factory";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "BonusprogramSettings",
  components: {UserBonusTargetPopup},
  data() {
    return {
      absenceQuotas: [],
      absenceQuota: null,
      absenceQuotaPopupActive: false,
    }
  },

  computed: {
    userId() {
      return this.$route.params.id;
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {

      const quotas = ApiService.get(`user-bonus-target?filter[user_id]=${this.userId}`);

      Promise.all([quotas]).then(responses => {
        this.absenceQuotas = responses[0].data.result;
        this.$refs.card.removeRefreshAnimation();
      });
    },

    onClickCreateAbsenceQuota() {
      const absenceQuota = ModelFactory.create('user_bonus_target');
      absenceQuota.starts_at = moment().format('YYYY-MM-DD');
      absenceQuota.user_id = this.userId;

      this.absenceQuota = absenceQuota;
      this.absenceQuotaPopupActive = true;
    },
    onClickEditAbsenceQuota(absenceQuota) {
      this.absenceQuota = cloneDeep(absenceQuota);
      this.absenceQuotaPopupActive = true;
    }
  },

  filters: {
    date(date) {
      if (!date) {
        return null;
      }

      return moment(date).format('DD.MM.YYYY')
    }
  }
}
</script>

<style scoped>

</style>