<template>
  <vx-card title="Arbeitszeitbericht Einstellungen" ref="card">

    <vs-table :data="timeSheetSettings" class="mb-6">
      <template slot="header">
        <h5 class="mb-2">Arbeitszeiten Einstellungen</h5>
        <vs-button size="small" class="mb-2" @click="onClickCreateTimeSheetSetting">Hinzufügen</vs-button>
      </template>

      <template slot="thead">
        <vs-th>Zeitspanne</vs-th>
        <vs-th>Urlaubsanspruch</vs-th>
        <vs-th>Stunden/Woche</vs-th>
        <vs-th>Aktionen</vs-th>
      </template>


      <template slot-scope="{data}">
        <vs-tr v-for="row in data">
          <vs-td>
            {{ row.starts_at | date }} - {{ row.ends_at | date }}
          </vs-td>
          <vs-td>{{ row.vacation_entitlement_days }}</vs-td>
          <vs-td>{{ row.target_hours }}</vs-td>
          <vs-td style="width: 100px" class="text-right">
            <vs-button
                @click="onClickEditTimeSheetSetting(row)"
                class="inline-block"
                size="small"
                color="warning"
                type="filled"
                icon="edit">
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-table :data="absenceQuotas">
      <template slot="header">
        <h5 class="mb-2">Abwesenheitskontingenten</h5>
        <vs-button size="small" class="mb-2" @click="onClickCreateAbsenceQuota">Hinzufügen</vs-button>
      </template>

      <template slot="thead">
        <vs-th>Bezeichnung</vs-th>
        <vs-th>Zeitspanne</vs-th>
        <vs-th>Tage</vs-th>
        <vs-th style="width: 100px">Aktionen</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr v-for="row in data">
          <vs-td>
            {{ row.name }}
          </vs-td>
          <vs-td>
            {{ row.starts_at | date }} - {{ row.ends_at | date }}
          </vs-td>
          <vs-td>
            {{ row.days }}
          </vs-td>
          <vs-td style="width: 100px" class="text-right">
            <vs-button
                class="inline-block"
                size="small"
                color="warning"
                type="filled"
                icon="edit"
                @click="onClickEditAbsenceQuota(row)">
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <time-sheet-setting-popup
        @change="fetchData"
        :time-sheet-setting="timeSheetSetting"
        :active.sync="timeSheetSettingPopupActive">
    </time-sheet-setting-popup>

    <absence-quota-popup
        @change="fetchData"
        :absence-quota="absenceQuota"
        :active.sync="absenceQuotaPopupActive">
    </absence-quota-popup>

  </vx-card>
</template>

<script>
import moment from 'moment';
import ApiService from "../../../../api";
import TimeSheetSettingPopup from "./TimeSheetSettingPopup";
import AbsenceQuotaPopup from "./AbsenceQuotaPopup";
import ModelFactory from "../../../../mixins/model/model-factory";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "WorkingTimeSettings",
  components: {AbsenceQuotaPopup, TimeSheetSettingPopup},
  data() {
    return {
      timeSheetSettings: [],
      absenceQuotas: [],
      timeSheetSetting: null,
      absenceQuota: null,
      timeSheetSettingPopupActive: false,
      absenceQuotaPopupActive: false,
    }
  },

  computed: {
    userId() {
      return this.$route.params.id;
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$refs.card.refreshcard();

      const settings = ApiService.get(`user-time-sheet-setting?filter[user_id]=${this.userId}`),
          quotas = ApiService.get(`absence-quota?filter[user_id]=${this.userId}`);

      Promise.all([settings, quotas]).then(responses => {
        this.timeSheetSettings = responses[0].data.result;
        this.absenceQuotas = responses[1].data.result;
        this.$refs.card.removeRefreshAnimation();
      });
    },

    onClickCreateAbsenceQuota() {
      const absenceQuota = ModelFactory.create('absence_quotas');
      absenceQuota.starts_at = moment().format('YYYY-MM-DD');
      absenceQuota.user_id = this.userId;

      this.absenceQuota = absenceQuota;
      this.absenceQuotaPopupActive = true;
    },
    onClickEditAbsenceQuota(absenceQuota) {
      this.absenceQuota = cloneDeep(absenceQuota);
      this.absenceQuotaPopupActive = true;
    },
    onClickCreateTimeSheetSetting() {
      const timeSheetSetting = ModelFactory.create('user_time_sheet_settings');
      timeSheetSetting.starts_at = moment().format('YYYY-MM-DD');
      timeSheetSetting.user_id = this.userId;

      this.timeSheetSetting = timeSheetSetting;
      this.timeSheetSettingPopupActive = true;
    },
    onClickEditTimeSheetSetting(timeSheetSetting) {
      this.timeSheetSetting = cloneDeep(timeSheetSetting);
      this.timeSheetSettingPopupActive = true;
    }
  },

  filters: {
    date(date) {
      if (!date) {
        return null;
      }

      return moment(date).format('DD.MM.YYYY')
    }
  }
}
</script>

<style scoped>

</style>