<template>
  <vs-popup :title="title" :active="visible" @close="onClickClose">
    <template v-if="timeSheetSetting">
      <vs-row class="mb-4" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Zeitspanne:</vs-col>
        <vs-col vs-w="4">
          <flat-pickr v-model="timeSheetSetting.starts_at"
                      :config="flatPickrConfig.start"
                      @on-change="onChangeStartAt"
                      class="w-full">
          </flat-pickr>
        </vs-col>
        <vs-col vs-w="1" class="text-center">bis</vs-col>
        <vs-col vs-w="4">
          <flat-pickr v-model="timeSheetSetting.ends_at"
                      :key="endPickerKey"
                      :config="flatPickrConfig.end"
                      class="w-full">
          </flat-pickr>
        </vs-col>
      </vs-row>

      <vs-row class="mb-4" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Urlaubsanspruch:</vs-col>
        <vs-col vs-w="3">
          <vs-input type="number" class="w-full" v-model="timeSheetSetting.vacation_entitlement_days" step="0.5"
                    min="1"></vs-input>
        </vs-col>
      </vs-row>

      <vs-row class="mb-4" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Montag:</vs-col>
        <vs-col vs-w="3">
          <vs-input type="number" class="w-full" v-model="timeSheetSetting.mon" step="0.5" min="1"
                    @change="onChangeHours"></vs-input>
        </vs-col>
        <vs-col vs-w="3" class="font-bold pl-3">Dienstag:</vs-col>
        <vs-col vs-w="3">
          <vs-input type="number" class="w-full" v-model="timeSheetSetting.tue" step="0.5" min="1"
                    @change="onChangeHours"></vs-input>
        </vs-col>
      </vs-row>

      <vs-row class="mb-4" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Mittwoch:</vs-col>
        <vs-col vs-w="3">
          <vs-input type="number" class="w-full" v-model="timeSheetSetting.wed" step="0.5" min="1"
                    @change="onChangeHours"></vs-input>
        </vs-col>
        <vs-col vs-w="3" class="font-bold pl-3">Donnerstag:</vs-col>
        <vs-col vs-w="3">
          <vs-input type="number" class="w-full" v-model="timeSheetSetting.thu" step="0.5" min="1"
                    @change="onChangeHours"></vs-input>
        </vs-col>
      </vs-row>

      <vs-row class="mb-4" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Freitag:</vs-col>
        <vs-col vs-w="3">
          <vs-input type="number" class="w-full" v-model="timeSheetSetting.fri" step="0.5" min="1"
                    @change="onChangeHours"></vs-input>
        </vs-col>
        <vs-col vs-w="3" class="font-bold pl-3">Samstag:</vs-col>
        <vs-col vs-w="3">
          <vs-input type="number" class="w-full" v-model="timeSheetSetting.sa" step="0.5" min="1"
                    @change="onChangeHours"></vs-input>
        </vs-col>
      </vs-row>

      <vs-row class="mb-4" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Sonntag:</vs-col>
        <vs-col vs-w="3">
          <vs-input type="number" class="w-full" v-model="timeSheetSetting.sun" step="0.5" min="1"
                    @change="onChangeHours"></vs-input>
        </vs-col>
      </vs-row>

      <vs-row class="mb-8" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Stunden/Woche:</vs-col>
        <vs-col vs-w="2">
          {{ timeSheetSetting.target_hours }}
        </vs-col>
      </vs-row>

      <div class="flex">
        <vs-button color="danger" v-if="!isNew" @click="onClickDelete">Löschen</vs-button>
        <vs-button class="ml-auto" color="primary" type="border" @click="onClickClose">Abbrechen</vs-button>
        <vs-button class="ml-2" color="success" @click="onClickSave">Speichern</vs-button>
      </div>

    </template>
  </vs-popup>
</template>

<script>
import moment from "moment";
import flatPickr from 'vue-flatpickr-component';
import ApiService from "../../../../api";

export default {
  name: "TimeSheetSettingPopup",

  components: {
    flatPickr
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    timeSheetSetting: {
      type: Object,
      default: () => {
        return null;
      }
    },
  },

  computed: {
    title() {
      if (this.isNew) {
        return 'Arbeitszeit-Einstellung erstellen';
      }

      return 'Arbeitszeit-Einstellung bearbeiten';
    },
    visible() {
      return this.active;
    },
    isNew() {
      if (!this.timeSheetSetting) {
        return false;
      }

      return !this.timeSheetSetting.id;
    }
  },

  mounted() {
    if (this.active && this.timeSheetSetting) {
      this.updateDatePicker();
    }
  },

  data() {
    return {
      endPickerKey: 1,
      flatPickrConfig: {
        end: {
          altFormat: 'd.m.Y',
          dateFormat: 'Y-m-d',
          altInput: true,
          allowInput: true,
          enableTime: false
        },
        start: {
          altFormat: 'd.m.Y',
          dateFormat: 'Y-m-d',
          altInput: true,
          allowInput: true,
          enableTime: false,
        }
      },
      notifyError: {
        title: 'Fehler',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      },
      notifySuccess: {
        title: 'Erfolgreich',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
      }
    }
  },

  methods: {
    onClickClose() {
      this.$emit('update:active', false)
    },
    onChangeStartAt() {
      this.updateDatePicker();
    },
    updateDatePicker() {
      this.flatPickrConfig.end.minDate = moment(this.timeSheetSetting.starts_at).add(1, 'day').format('YYYY-MM-DD')
      this.endPickerKey = Math.random();
    },
    onClickSave() {

      if (this.isNew) {
        ApiService.post('user-time-sheet-setting', this.timeSheetSetting).then(() => {
          this.$vs.notify({
            ...this.notifySuccess,
            text: 'Die Arbeitszeit-Einstellung wurde gespeichert.'
          })
          this.$emit('change', this.timeSheetSetting);
          this.onClickClose();
        }).catch(error => {
          error.handleGlobally();
        })
        return;
      }

      ApiService.put('user-time-sheet-setting/' + this.timeSheetSetting.id, this.timeSheetSetting).then(() => {
        this.$vs.notify({
          ...this.notifySuccess,
          text: 'Die Arbeitszeit-Einstellung wurde gespeichert.'
        })
        this.$emit('change', this.timeSheetSetting);
        this.onClickClose();
      }).catch(() => {
        this.$vs.notify({
          ...this.notifyError,
          text: 'Die Arbeitszeit-Einstellung konnte nicht gespeichert werden'
        })
      })

    },
    onClickDelete() {
      if (this.isNew) {
        return;
      }

      ApiService.delete('user-time-sheet-setting/' + this.timeSheetSetting.id).then(() => {
        this.$vs.notify({
          ...this.notifySuccess,
          text: 'Die Arbeitszeit-Einstellung wurde gelöscht.'
        })
        this.$emit('change', null);
        this.onClickClose();
      }).catch(() => {
        this.$vs.notify({
          ...this.notifyError,
          text: 'Die Arbeitszeit-Einstellung konnte nicht gelöscht werden',
        })
      })
    },
    onChangeHours() {
      let targetHours = 0;

      targetHours += (parseFloat(this.timeSheetSetting.mon) || 0);
      targetHours += (parseFloat(this.timeSheetSetting.tue) || 0);
      targetHours += (parseFloat(this.timeSheetSetting.wed) || 0);
      targetHours += (parseFloat(this.timeSheetSetting.thu) || 0);
      targetHours += (parseFloat(this.timeSheetSetting.fri) || 0);
      targetHours += (parseFloat(this.timeSheetSetting.sa) || 0);
      targetHours += (parseFloat(this.timeSheetSetting.sun) || 0);

      this.timeSheetSetting.target_hours = targetHours;
    }
  },

  watch: {
    active() {
      if (this.active && this.timeSheetSetting) {
        this.updateDatePicker();
        this.onChangeHours();
      }
    }
  }
}
</script>