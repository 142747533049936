<template>
  <vs-popup :title="title" :active="visible" @close="onClickClose">
    <template v-if="absenceQuota">
      <vs-row class="mb-4" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Quartal:</vs-col>
        <vs-col vs-w="4">
          <vs-input v-model="absenceQuota.quarter" class="w-full"></vs-input>
        </vs-col>
        <vs-col vs-w="1" class="text-center font-bold">Jahr:</vs-col>
        <vs-col vs-w="4">
          <vs-input v-model="absenceQuota.year" class="w-full"></vs-input>
        </vs-col>
      </vs-row>

      <vs-row class="mb-4" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Zielpunkte:</vs-col>
        <vs-col vs-w="9">
          <vs-input v-model="absenceQuota.bonus_target" class="w-full" type="number" step="0.10" min="1"></vs-input>
        </vs-col>
      </vs-row>

        <vs-row class="mb-4" vs-align="center">
            <vs-col vs-w="3" class="font-bold">Erreichte Punkte:</vs-col>
            <vs-col vs-w="9">
                <vs-input v-model="absenceQuota.reached" class="w-full" type="number" step="0.10" min="0"></vs-input>
            </vs-col>
        </vs-row>

        <vs-row class="mb-4" vs-align="center">
            <vs-col vs-w="3" class="font-bold">Ausz. € wenn erreicht:</vs-col>
            <vs-col vs-w="9">
                <vs-input v-model="absenceQuota.payout" class="w-full" type="number" step="0.10" min="0"></vs-input>
            </vs-col>
        </vs-row>

      <vs-row class="mb-8" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Notiz:</vs-col>
        <vs-col vs-w="9">
          <vs-textarea class="w-full" v-model="absenceQuota.notice" ></vs-textarea>
        </vs-col>
      </vs-row>

        <vs-row class="mb-8" vs-align="center">
            <vs-col vs-w="3" class="font-bold">E-Mail Benachrichtung verschicken?</vs-col>
            <vs-col vs-w="3">
                <vs-checkbox class="w-full" v-model="absenceQuota.send_mail" ></vs-checkbox>
            </vs-col>
            <vs-col vs-w="3" class="font-bold">Kostenersatz anlegen?</vs-col>
            <vs-col vs-w="3">
                <vs-checkbox class="w-full" v-model="absenceQuota.add_reimbursement" ></vs-checkbox>
            </vs-col>
        </vs-row>


      <div class="flex">
        <vs-button color="danger" v-if="!isNew" @click="onClickDelete">Löschen</vs-button>
        <vs-button class="ml-auto" color="primary" type="border" @click="onClickClose">Abbrechen</vs-button>
        <vs-button class="ml-2" color="success" @click="onClickSave">Speichern</vs-button>
      </div>

    </template>
  </vs-popup>
</template>

<script>
import moment from "moment";
import flatPickr from 'vue-flatpickr-component';
import ApiService from "../../../../api";

export default {
  name: "UserBonusTargetPopup",

  components: {
    flatPickr
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    absenceQuota: {
      type: Object,
      default: () => {
        return null;
      }
    },
  },

  computed: {
    title() {
      if (this.isNew) {
        return 'Bonusziel erstellen';
      }

      return 'Bonusziel bearbeiten';
    },
    visible() {
      return this.active;
    },
    isNew() {
      if (!this.absenceQuota) {
        return false;
      }

      return !this.absenceQuota.id;
    }
  },

  mounted() {
  },

  data() {
    return {
      endPickerKey: 1,
      flatPickrConfig: {
        end: {
          altFormat: 'd.m.Y',
          dateFormat: 'Y-m-d',
          altInput: true,
          allowInput: true,
          enableTime: false
        },
        start: {
          altFormat: 'd.m.Y',
          dateFormat: 'Y-m-d',
          altInput: true,
          allowInput: true,
          enableTime: false,
        }
      },
      notifyError: {
        title: 'Fehler',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      },
      notifySuccess: {
        title: 'Erfolgreich',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
      }
    }
  },

  methods: {
    onClickClose() {
      this.$emit('update:active', false)
    },
    onClickSave() {
      if (this.isNew) {
        ApiService.post('user-bonus-target', this.absenceQuota).then(() => {
          this.$vs.notify({
            ...this.notifySuccess,
            text: 'Das Bonusziel wurde gespeichert.'
          })
          this.$emit('change', this.absenceQuota);
          this.onClickClose();
        }).catch(() => {
          this.$vs.notify({
            ...this.notifyError,
            text: 'Das Bonusziel konnte nicht gespeichert werden'
          })
        })
        return;
      }

      ApiService.put('user-bonus-target/' + this.absenceQuota.id, this.absenceQuota).then(() => {
        this.$vs.notify({
          ...this.notifySuccess,
          text: 'Das Bonusziel wurde gespeichert.'
        })
        this.$emit('change', this.absenceQuota);
        this.onClickClose();
      }).catch(() => {
        this.$vs.notify({
          ...this.notifyError,
          text: 'Das Bonusziel konnte nicht gespeichert werden'
        })
      })

    },
    onClickDelete() {
      if (this.isNew) {
        return;
      }

      ApiService.delete('user-bonus-target/' + this.absenceQuota.id).then(() => {
        this.$vs.notify({
          ...this.notifySuccess,
          text: 'Das Bonusziel wurde gelöscht.'
        })
        this.$emit('change', null);
        this.onClickClose();
      }).catch(() => {
        this.$vs.notify({
          ...this.notifyError,
          text: 'Das Bonusziel konnte nicht gelöscht werden',
        })
      })
    }
  },

  watch: {
  }
}
</script>